<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10065')}} &middot; {{t('10066')}} 
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub event">
			<!-- content -->
			<section class="content" v-if="mev0102.length > 0">
				<div class="box">
          <div class="event__wrap">
              <h3 class="list__title info-title" style="display:inline-block">
                    {{mev0102[0].PROMO_NM}}
              </h3>
              <div class="list__date grey3">
					{{dayjs(mev0102[0].SDATE).format("YYYY.MM.DD")}} ~ {{dayjs(mev0102[0].EDATE).format("YYYY.MM.DD")}}
              </div>
          </div>
				</div>
			<div class="event__content">
				<img :src="mev0102[0].PROMO_DETAIL_URL" :alt="mev0102[0].PROMO_NM">
			</div>
			<div class="event__rooms" v-if="mev0102[0].HOTEL_NO !== null">
				<h3 class="event__roomstitle">
					{{t('10049')}}
				</h3>
				<rooms-list :list="mev0102.map(room => ({
					...room,
					SALES_AMT: room.SALESAMT_NIGHT,
					IMG_URL: room.HOTEL_IMG,
					mHOTEL_SLOGAN: room.HOTEL_DESC,
					mHOTEL_LOCTEXT: room.LOCATION_TEXT,
					mHOTEL_SLOGAN2: room.HOTEL_DESC2,
					SALES_DAYUSE: room.DAYUSE_AMT,
					event: 'Y',
				}))" />
			</div>
		</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted } from "vue"
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";
import RoomsList from '@/components/RoomsList';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");
export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const { t }= useI18n() //번역필수 모듈

    const mev0102 = computed(() => store.state.mev01.mev0102);
		const promoNo = route.params.id;

		const goBack = () => {
      router.go(-1);
		};

		onMounted(()=>{
			store.dispatch("mev01/fetchMev0102",{
				proc_cd: "01",
				promo_no: promoNo
			});
		});

		return {
			dayjs,
			goBack,
			mev0102,
			t,
			i18n,
		}
	},
	components: {
		RoomsList,
	},
}
</script>